@import 'vars';

@mixin smaller-mobile {
  @media (max-width: $screen-xs) {
        @content;
    }
}

@mixin landscape {
    @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        @content;
    }
}

@mixin mobile {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
        @content;
    }
}

@mixin max-768 {
  @media (max-width: $screen-sm-max) {
    @content;
  }
}

@mixin tablet {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
        @content;
    }
}

@mixin desktop-md {
    @media (min-width: $screen-md) {
        @content;
    }
}

@mixin desktop-lg {
    @media (min-width: $screen-lg) {
        @content;
    }
}

@mixin retina {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}


//mixin button hover
@mixin hover($color, $percent , $fontColor) {
    &:hover, &:focus, &:active {
        background: lighten($color,$percent);
        color: $fontColor;
        border:1px solid lighten($color,$percent);
    }
}
