@import "vars";

.navbar-default {
    background:$navbar-green;
    padding-top:50px;
    position: relative;
    margin-bottom:-1px;

    .container {
        max-width:960px;
    }

    &:before {
        content:"";
        height: 50px;
        width: 100%;
        background:$top-header;
        position: absolute;
        top:0;
        left: 0;
    }

    .navbar-nav {
        > li {
            > a {
                color: $top-header;
                position: relative;
                font-size:18px;
                padding-top:25px;
                padding-bottom:25px;
                font-family:$sans;
                font-weight:$light;
                text-transform:uppercase;

                &:before {
                    content:"";
                    height: 15px;
                    width:1px;
                    right: 0;
                    top: calc(50% - 7px);
                    position: absolute;
                    background:$top-header;
                }

                &:hover {
                    background:$navbar-hover;
                    color: #fff;
                }
            }

            &:last-child {
                a:before {
                    display: none;
                }
            }
        }
        > .active {
            > a {
                background:$navbar-hover;
                color: #fff;
            }
        }
    }
}

.contact-us {
    font-size:26px;
    color: #396d29;
}

.dropdown-menu {
    padding:0;
    > li {

        > a {
            background: $navbar-green;
            border:none;
            margin:none;
            color: $top-header;
            position: relative;
            font-size:18px;
            padding-top:25px;
            padding-bottom:25px;
            font-family:$sans;
            font-weight:$light;
        }
    }
}


.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover
{
    background: $navbar-hover;
    color: #fff;
}

.navbar-default .navbar-toggle {
    border-color:#fff;

    .icon-bar {
        background-color: #fff;
    }
}

@media (max-width: 767px) {
.navbar-default .navbar-nav .open .dropdown-menu>li>a {
        color: #fff;
        padding:15px 20px;
    }
}
