@import 'components/_all';

// Icon Sizing
.material-icons {
    &.md-18 { font-size: 18px; }
    &.md-24 { font-size: 24px; }
    &.md-36 { font-size: 36px; }
    &.md-48 { font-size: 48px; }
    &.star {
        width: 23px;
        overflow: hidden;
    }
}

body {
    font-family:$sans;
    font-weight:$normal;
    margin:0;
    font-size:16px;
    color: $black;
    background: white;
}

a {
    // transition:all ease .2s;
}
h1 {
    font-size:55px;
    font-family:$sans;
    font-weight:$bold;
    text-transform:uppercase;
    margin-top:0;
    margin-bottom:100px;
    color: #396d29;
}
h2 {
    font-size:36px;
    font-weight:$bold;
    color: #396d29;
}
h3 {
    font-size:26px;
    margin-top:30px;
}

h4 {
    font-size:25px;
}

h5 {
    font-size:18px;
}

p {
    color:$black;
}

blockquote {
    font-size:30px;
    font-family:$raleway;
    font-weight:$extra-light;
    font-style:italic;
    padding: 30px 100px;
    border:none;
    text-align:center;
    margin:30px 0 50px;
    position: relative;

    &:before, &:after {
        content: '';
        position: absolute;
        left: 50%;
        width: 270px;
        height: 1px;
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #dedede 50%, transparent 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00000000',GradientType=1 );
    }
    &:before {
        top:0;
    }

    &:after {
        bottom:0;
    }
}


// overriding bootstrap style
.page-header {
    padding:0;
    margin:0;
    border:none;
    background: white;
}

.input-group {
    z-index: 0;
}

//Padding
.no-padding {padding: 0 !important;}
.no-padding-left {padding-left: 0 !important;}
.no-padding-right {padding-right: 0 !important;}

// Margin
.no-margin {margin: 0 !important;}
.no-margin-left {margin-left: 0 !important;}
.no-margin-right {margin-right: 0 !important;}

// Align
.align-left {text-align: left;}
.align-center {text-align: center;}
.align-right {text-align: right;}

.btn {
    &:active , &:focus , &:hover {
        outline:none;
    }
}

//global styles
.list-arrow {
    margin-bottom:20px;
    li {
        display: block;
        margin:8px 0;
        &:before {
            content: "\e258";
            font-family:'Glyphicons Halflings';
            // background: $green;
            border-radius: 14px;
            font-size: 13px;
            padding: 3px 4px;
            margin-right:10px;
            color: white;
            vertical-align: middle;
        }
    }
}

.btn-default {
    height:50px;
    max-width:370px;
    width:100%;
    text-transform:uppercase;
    font-family:$raleway;
    font-weight:$medium;
    font-size:18px;
    line-height:36px;
    border-radius:5px;
    transition:all ease .4s;
    border:none;
    outline:none;
}


.btn-green {
    background: $body-green;
    color:white;
    box-shadow:inset 0px -3px 0px $navbar-green;

    @include hover($body-green,10%,white);
}

.logo {
    position: absolute;
    left: 50%;
    top: 35px;
    z-index:1;
    margin-left:calc(-140px /2);
    max-width:140px;
}
.about-video {
    .thumb , .caption {
        display: inline;
    }

    .caption {
        font-size:24px;
        margin-top:20px;
    }
}

.bg-home {
    background:url(../img/pattern-article.jpg) repeat;
}

section {
    &.welcome {
        padding:50px 0 120px 0;
        background:rgba(0,0,0,0.2);

        .title {
            font-size:50.6px;
            font-weight:$light;
            color: #000;
            font-family:$sans;
        }

        p {
            font-size:18px;
        }

        .tile-wrapper {
            max-width:$tile-wrapper;
            width:100%;
            height:$tile-height * 2;
            margin:0 auto;

            .tile {
                width:$tile-wrapper/2;
                height:$tile-height;
                float: left;
                /*padding-left:60px;
                padding-right:60px;*/

                &.blue {
                    background: #38b9e1;
                }
                &.orange {
                    background:#f6ad2f;
                }
                &.purple {
                    background:#8a509b;
                }
                &.red {
                    background:#e53933;
                }

                .icon {
                    display: table-cell;
                    vertical-align:middle;
                }
                .title {
                    font-size:35px;
                    color: #fff;
                    font-weight:$light;
                    text-transform:uppercase;
                    display: table-cell;
                    text-align:center;
                    padding-left:20px;
                    vertical-align:middle;

                    span {
                        font-weight:$normal;
                    }
                }
                .link {
                    text-transform:uppercase;
                    border:1px solid white;
                    padding:5px 10px;
                    font-size:20px;
                    font-weight:$light;
                    color: #fff;
                    display: block;
                }
            }
        }
    }
    /*welcome*/
}
/*section*/
footer {
    .sitemap {
        padding:30px 0;
        background:$navbar-green;
        text-align:center;

        .list-sitemap {
            margin:0;

            li {
                border-right:1px solid $top-header;

                a {
                    color: $top-header;
                    margin:0 10px;
                }
                &:last-child {
                    border:none;
                }
            }
        }
    }
}

.inner-page {
    padding: 160px 0;
    background:url(../img/pattern-article.jpg) repeat;
    position: relative;

    &.padding-bottom-40 {
        padding-bottom:40px;
    }

    p {
        color: $text-green;
        font-size:26px;
        margin-bottom:48px;
    }

    /*
    &:before {
        content:"";
        background: url(../img/circle-top.png) no-repeat;
        width:742px;
        height:604px;
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0.1;
    }

    &:after {
        content:"";
        background:url(../img/circle-bottom.png) no-repeat;
        width:701px;
        height:617px;
        left: 0;
        bottom:0;
        position: absolute;
        opacity: 0.1;
    }*/
}

.what-thumb {
    margin-bottom:40px;
    height:240px;

    img {
        width:100%;
    }

    span {
        display: block;
    }
}

.testimonial {
    background:#e6e7e8;
    border-radius:5px;
    border:5px solid white;
    box-shadow:3px 4px 0px rgba(0,0,0,0.27);
    float:left;
    padding:20px;
    position: relative;

    &:before {
        content:"";
        background:url(../img/bub-point.png) no-repeat;
        position: absolute;
        right:20px;
        bottom:-35px;
        width:69px;
        height:41px;
    }

    .text {
        text-align:center;
        width:calc(100% - 150px);
        float:left;

        p {
            font-size:20px;
            margin:0;
            font-style:italic;
            font-family:$lora;

            &:nth-child(1) {
                color: #4d9536;
            }

            + p {
                margin-top:15px;
                font-family:$lora;
                color: #000000;
            }
        }
    }

    .thumb {
        width:148px;
        float:left;
    }
}

.tuser {
    text-align:right;
    float: right;
    margin-right:80px;
    margin-top: 15px;
    margin-bottom:70px;

    h4 {
        margin:0;
        font-weight:bold;
    }

    p {
        color: #000;
        font-size:14px;
        margin:0;
    }
}

.contact-inner {
    background:rgba(255,255,255,.7);
    padding-top:40px;
    padding-bottom:40px;
    margin-top:20px;
    position: relative;
    z-index:1;

    p {
        font-size:22.5px;
    }
}

.about-video {
    margin:100px 0;
}

.popup {
    margin-bottom:20px;
    display: block;
    border-radius:32px;
    overflow:hidden;
}

.logo-produk {
    margin-bottom:50px;
}

.pic {
    margin:30px 0;

    span {
        float:left;
        margin-top:5px;
    }
}

.join-us {
    .pic {
        height:380px;
        text-align:center;

        img {
            display: inline;
        }

        p {
            margin-top:10px;
            text-align:left;
        }
    }
}

.form-horizontal {
    .form-group {
        margin-bottom: 38px;
    }
    .control-label {
        padding: 14px 25px;
        background: #4d9536;
        text-align: left;
        color: white;
        font-weight: normal;
        font-size: 21px;
        position: relative;

        &:before {
            content:"";
            position: absolute;
            right: -8px;
            top:50%;
            margin-top:calc(-31px/2);
            background:url(../img/label-arrow.png) no-repeat;
            width:10px;
            height: 31px;
        }
    }

    .form-control {
        height: 58px;
    }
}

.dropdown-menu > li > a {
    padding-top:10px;
    padding-bottom:10px;
}

.img-instansi {
    width: 100%;
    height: auto;
}

a.question {
    color: #000;
    padding:10px;
    display: block;
    text-decoration:none;
}

.the-brand {
    position: relative;
    transition:all ease .2s;
    text-align:center;
    width: calc(99%/3);
    display: inline-block;
    max-height:240px;
    margin-bottom:80px;
    padding:20px;

    &:after {
        content:"";
        transition:all ease .2s;
    }

    a {
        display: none;
        background: $body-green;
        color: #fff;
        position: absolute;
        top:50%;
        margin-top:-20px;
        left: 50%;
        margin-left: -65px;
        z-index: 2;
        width: 130px;
        height: 40px;
        line-height:40px;
        text-decoration:none;
        transition:all ease .2s;
    }

    &:hover {
        &:after {
            content:"";
            background:rgba(0,0,0,0.2);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        a {
            display: block;
        }
    }
}
